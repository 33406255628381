@import '../../assets/main';

.PersonalPlaybackListContainer {
  background: $white;
  box-shadow: 0px 5px 5px rgba($color: $black, $alpha: 0.1);
  border-radius: 8px;
}

.courseMedia {
  height: 30vh;
}

.courseImage {
  height: 100%;
  width: 100%;
  margin: 0 auto;
  display: block;
  object-fit: cover;
}
